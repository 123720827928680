import { NavLink  } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Sidebar, Menu, Divider, Image, Icon } from 'semantic-ui-react';
import IHamburgerProps from './IHamburgerProps';
import config from 'Application/configurations/appConfig';
import './hamburger.css';
import { useContext } from 'react';
import { StateContext } from 'Application/state/StateContext';

/** App layout sidebar/hamburger menu
 * This component will add hamburger menu to the "right" side of the viewport.
 * @param {boolean} hamburgerMenuIsOpen - Is sidebar open or closed
 * @param {function} setHamburgerMenuIsOpen - React SetState to set sidebar open or closed
 **/
const Hamburger: React.FC<IHamburgerProps> = ({hamburgerMenuIsOpen, setHamburgerMenuIsOpen}: IHamburgerProps) => {

    const { t } = useTranslation();
    const { userProfile } = useContext(StateContext);

    return (
        <Sidebar
            as={Menu}
            fixed='right'
            animation='overlay'
            className='patch borderless hamburger'
            onHide={() => setHamburgerMenuIsOpen(false)}
            vertical
            visible={hamburgerMenuIsOpen}
        >

            <Menu.Item className="logo">
                <Image size='tiny' src="/img/logo.png" alt="logo" /> <strong>{t('app')}</strong>
            </Menu.Item>

            <Menu.Item as={NavLink} to='/' onClick={() => setHamburgerMenuIsOpen(false)} >
                <Icon name='home' className='app-header' /> {t('components.layout.hamburger.navigation.home')}
            </Menu.Item>

            <Menu.Item as={NavLink} to='/surveys' onClick={() => setHamburgerMenuIsOpen(false)} >
                <Icon name='question' className='app-header' /> {t('components.layout.hamburger.navigation.surveys')}
            </Menu.Item>

            {/* Management - Visible to admins only! */}
            { userProfile && userProfile.roles && userProfile.roles.find((item: any) => item.name==="Admin") &&
            //userProfile && userProfile.isAdmin &&
                <>
                <Divider/>
                <Menu.Item as={NavLink} to='/management' onClick={() => setHamburgerMenuIsOpen(false)}>
                    <Icon name='cogs' className='app-header' /> {t('components.layout.hamburger.navigation.management')}
                </Menu.Item>
            
                <Menu.Item as={NavLink} to='/versions' onClick={() => setHamburgerMenuIsOpen(false)}>
                    <Icon name='code branch' className='app-header' /> {t('components.layout.hamburger.navigation.versionHistory')}
                </Menu.Item>
                </>
            }
            
            <Divider/>
            
            {/* Link To SharePoint */}
            <a className="item" href={config.sharePointUri} target="_blank" rel="noopener noreferrer">
                <Icon name='folder' className='app-header' /> {t('components.layout.hamburger.navigation.sharepoint')}
            </a>

            <Divider/>

            <Menu.Item as={NavLink} to='/profile' className='custom bottom menu profile' onClick={() => { setHamburgerMenuIsOpen(false); }}> 
                <Icon name='user' className='app-header' /> {t('components.layout.hamburger.navigation.profile')}
            </Menu.Item>            
            <Menu.Item className='custom bottom menu privacy' href="/files/tietosuojaseloste.pdf" target="_blank" onClick={() => { setHamburgerMenuIsOpen(false); }}>
                <Icon name='file pdf' className='app-header' /> {t("components.layout.hamburger.navigation.privacy")}
            </Menu.Item>
            <Menu.Item
                as={NavLink}
                to='/logout'
                className='custom bottom menu logout'
                onClick={() => { 
                    setHamburgerMenuIsOpen(false);
                    userProfile.logoutRedirect();
                }}>
                <Icon name='sign out' className='app-header' /> {t('components.layout.hamburger.navigation.logout')}
            </Menu.Item>

            <Menu fixed='bottom'>
                <Menu.Item>
                    {t('app') + " " + config.version}
                </Menu.Item>
            </Menu>

        </Sidebar>
    );

}

export default Hamburger;
